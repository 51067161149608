import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Lenis from "@studio-freight/lenis";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Loader from "../Components/Loader/Loader";

// Lazy load the pages
const Home = lazy(() => import("../Home/Home"));
const Services = lazy(() => import("../Services/Services"));
const AboutUs = lazy(() => import("../AboutUs/AboutUs"));
const ContactUs = lazy(() => import("../ContactUs/ContactUs"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Pages = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="overflow-hidden bg-white">
        <Navbar />
        <div>
          {loading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <Suspense
              fallback={
                <div className="min-h-screen">
                  <Loader />
                </div>
              }
            >
              <div className="mt-24">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                </Routes>
              </div>
            </Suspense>
          )}
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default Pages;
