import React from "react";
import "./Loader.css";
import logo_workaxis from "../assets/logo.webp";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-wrapper">
        <div className="loader-circle"></div>
        <img
          src={logo_workaxis}
          alt="Work Axis Logo"
          className="loader-image"
        />
      </div>
    </div>
  );
};

export default Loader;
