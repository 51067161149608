import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import FooterLogo from "./footerlogo.webp";
import facebook from "./Facebook.webp";
import instagram from "./Instagram.webp";
import linkedin from "./LinkedIn.webp";
import twitter from "./Twitter.webp";
import whatsapp from "./WhatsApp.webp";

import AnimatedSection from "../Components/Animation/AnimatedSection";
import ZoomAnimated from "../Components/Animation/ZoomAnimated";

const Footer = () => {
  const [state, handleSubmit] = useForm("mbljrzdj");
  return (
    <>
      <div className="flex flex-col items-center justify-center bg-black w-full py-10">
        <div className="flex items-start justify-start w-full px-5 lg:px-10">
          <img src={FooterLogo} alt="footerlogo" className="w-48 md:w-60" />
        </div>
        <div className="flex flex-col items-center justify-center w-full text-white font-poppins py-3 px-5 lg:flex-row lg:gap-x-10 lg:justify-between lg:px-10">
          <div className="flex flex-col items-start justify-center w-full gap-y-5 lg:w-96">
            <AnimatedSection className="font-poppins text-lg text-gray-500 w-full text-left">
              WorkAxis is a premier recruitment partner, providing tailored
              staffing and HR solutions across various industries. We are
              dedicated to connecting businesses with top-tier talent through
              innovative, efficient, and cost-effective recruitment processes.
            </AnimatedSection>
          </div>
          <div className="flex flex-col items-start justify-center py-10 gap-y-2 font-poppins text-left w-full lg:w-96">
            <AnimatedSection className="text-xl">
              Get job notifications
            </AnimatedSection>
            <AnimatedSection className="text-sm text-gray-500">
              The latest job news, articles, sent to your inbox weekly
            </AnimatedSection>
            <div className="flex items-center justify-center w-full gap-x-2">
              <form
                onSubmit={handleSubmit}
                className="flex items-center w-full gap-x-2"
              >
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  className="w-full p-2 rounded-md text-black"
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
                <button
                  type="submit"
                  className="bg-primary text-white px-4 py-2 rounded-md"
                  disabled={state.submitting}
                >
                  {state.succeeded ? "Subscribed!" : "Subscribe"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center flex-col text-center text-white lg:mt-10">
          <AnimatedSection className="mb-2">
            <strong className="text-3xl underline">
              Contact <span className="text-gray-500">Info</span>
            </strong>
          </AnimatedSection>
          <div className="flex flex-col items-center justify-center mb-10">
            <div className="flex flex-col items-center justify-center">
              {/* Mobile Icon 1 */}
              <AnimatedSection className="mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  className="inline-block w-5 h-5 mr-2"
                >
                  <path
                    d="M50.7,45.3c-5.4,5.4-14.1,5.4-19.5,0L17.5,31.5c-5.4-5.4-5.4-14.1,0-19.5l2.6-2.6c1.4-1.4,3.7-1.4,5.1,0L30,12.6
             c1.4,1.4,1.4,3.7,0,5.1L26.9,21.8c-1.1,1.1-.3,3,1.3,4.6L38,36.2c1.6,1.6,3.5,2.3,4.6,1.3l4.1-4.1c1.4-1.4,3.7-1.4,5.1,0l2.6,2.6
             c1.4,1.4,1.4,3.7,0,5.1L50.7,45.3z"
                  />
                </svg>
                <a href="tel:+917418370542" className="text-white">
                  +91 74183 70542
                </a>
              </AnimatedSection>

              {/* Mobile Icon 2 */}
              <AnimatedSection className="mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  className="inline-block w-5 h-5 mr-2"
                >
                  <path
                    d="M48.5,36.5c-2.2,0-4.3-0.4-6.3-1.1c-0.6-0.2-1.3-0.1-1.8,0.3l-6.5,4.9c-5.4-2.9-9.8-7.4-12.7-12.7l4.9-6.5
             c0.4-0.5,0.5-1.2,0.3-1.8c-0.7-2-1.1-4.1-1.1-6.3c0-0.7-0.6-1.4-1.4-1.4h-8.6C12.6,12,12,12.6,12,13.4C12,34.1,29.9,52,50.6,52
             c0.7,0,1.4-0.6,1.4-1.4v-8.6C52,37.1,51.3,36.5,50.6,36.5z"
                  />
                </svg>
                <a href="tel:+917418602872" className="text-white">
                  +91 74186 02872
                </a>
              </AnimatedSection>
            </div>

            <div className="flex items-center justify-center">
              {/* Mail Icon */}
              <AnimatedSection>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  className="inline-block w-5 h-5 mr-2"
                >
                  <rect
                    x="8"
                    y="16"
                    width="48"
                    height="32"
                    rx="4"
                    ry="4"
                    fill="white"
                    stroke="black"
                    strokeWidth="2"
                  />
                  <polyline
                    points="8,16 32,36 56,16"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                  />
                </svg>
                <a href="mailto:info@workaxis.co" className="text-white">
                  info@workaxis.co
                </a>
              </AnimatedSection>
            </div>
          </div>
        </div>

        <hr className="h-0.5 bg-gray-900 w-96 md:w-full text-gray-900" />
        <div className="flex items-center justify-center gap-5 py-5">
          <ZoomAnimated>
            <a
              href="https://www.facebook.com/profile.php?id=61566877225675"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" className="w-12 h-12" />
            </a>
          </ZoomAnimated>
          <ZoomAnimated>
            <a
              href="https://x.com/work_axis"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" className="w-12 h-12" />
            </a>
          </ZoomAnimated>
          <ZoomAnimated>
            <a
              href="https://www.linkedin.com/company/workaxiscbe/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" className="w-12 h-12" />
            </a>
          </ZoomAnimated>
          <ZoomAnimated>
            <a
              href="https://www.instagram.com/workaxisindia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" className="w-12 h-12" />
            </a>
          </ZoomAnimated>
          <ZoomAnimated>
            <a
              href="https://wa.me/917418370542"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="WhatsApp" className="w-12 h-12" />
            </a>
          </ZoomAnimated>
        </div>
        <hr className="h-0.5 bg-gray-900 w-96 md:w-full text-gray-900" />
        <AnimatedSection className="text-xl text-white mt-5">
          © 2024 WorkAxis. All rights reserved.
        </AnimatedSection>
        <AnimatedSection className="text-md text-gray-400 mt-2">
          Designed and crafted by{" "}
          <a
            href="https://ansaribrahim.me/"
            className="text-blue-500 underline"
          >
            Ansar Ibrahim
          </a>
        </AnimatedSection>
      </div>
    </>
  );
};

export default Footer;
